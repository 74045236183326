@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
@import 'typography';
@import 'buttons';
@import 'shadows';

.card {
  @include shadow-xl;

  display: flex;
  overflow: hidden;
  width: 19rem;
  height: fit-content;
  height: 31.25rem;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0;
  border-radius: 1.5rem;
  background-color: $white;

  @include screen-size('medium') {
    width: 30rem;
  }
}

.text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem 1.5rem 0;
  gap: 0.5rem;
  text-align: left;

  h3 {
    @include display-xs;
    @include text-bold;
    @include font-primary;

    margin-bottom: 0;

    @include screen-size('medium') {
      @include display-sm;
    }
  }
}

.description {
  @include text-md;
  @include text-normal;
  @include font-secondary;

  color: $grey-900;

  a {
    color: $primary-pink;
  }
}

.tag {
  @include text-xs;
  @include text-bold;
  @include font-secondary;

  color: $grey-800;
}

.image {
  position: relative;
  width: 19rem;
  height: 17.875rem;
  border-radius: 0 0 1.5rem 1.5rem;

  @include screen-size('medium') {
    width: 30rem;
    height: 21.875rem;
  }
}

.alt-card {
  background-color: $primary-yellow;

  .tag {
    color: $black;
  }
}
