@mixin shadow-3xl {
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
}

@mixin shadow-2xl {
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
}

@mixin shadow-xl {
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

@mixin shadow-lg {
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

@mixin shadow-md {
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

@mixin shadow-sm {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1);
}

@mixin shadow-xs {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
